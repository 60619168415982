@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800");
p, h1, h2, h3, h4, a {
  font-family: 'Raleway', sans-serif !important; }

.site-nav {
  margin: auto;
  padding: 12px 0 !important;
  max-width: 1200px; }

#AccessibleNav {
  background: #f5ce4e; }

.grid__item.small--one-half.medium-up--one-quarter.text-right.site-header__icons {
  width: 75%; }

#SiteNav li {
  width: 12%; }

.site-footer {
  background: #575757;
  padding-bottom: 30px; }

.site-footer {
  margin-bottom: 0;
  margin-top: 0; }

.site-footer a {
  color: #fff; }

.site-footer__copyright {
  color: #fff;
  text-align: left; }

#Subscribe {
  background: #3faa2a; }

.grid__item.one-fifth.footerlogoimg {
  margin-top: 55px; }

.grid__item.one-fifth.thirdone {
  width: 12%; }

.grid__item.one-fifth.firstone {
  width: 16%; }

.grid__item.one-fifth.fourthone {
  width: 32%; }

.site-footer__newsletter label {
  color: #fff; }

.grid__item.medium-up--one-half.rightside {
  width: 100%;
  max-width: 275px;
  float: right; }

.footer_top {
  display: block;
  overflow: hidden;
  background: #f5ce4e;
  margin-top: 55px;
  padding: 20px 0; }

.grid__item.one-quarter.ftrtop {
  padding-left: 0; }

.footer_top_img {
  float: left; }

.footer_top_text h4 {
  margin-bottom: 0; }

.footer_top_text {
  overflow: hidden;
  padding-left: 15px; }

#shopify-section-1529697431854 {
  padding-bottom: 0; }

#shopify-section-1531423301741 {
  padding-bottom: 60px; }

.text_with_image_main {
  width: 50%; }

.image_top {
  float: left; }

.text_top {
  overflow: hidden;
  padding-left: 20px; }

.thumbnails-wrapper li {
  width: 100%; }

.productmainimage {
  display: block;
  width: 80%;
  float: right; }

.thumbnails-wrapper {
  width: 15%;
  float: left; }

.grid__item.one-whole.imgtop {
  margin-bottom: 20px; }

.text_top h4 {
  margin-bottom: 0;
  font-size: 24px; }

.grid__item.one-whole.threblk.frst {
  background: #fff3cb;
  padding: 15px;
  padding-bottom: 0;
  position: relative; }

.grid__item.one-whole.threblk.scnd {
  background: #e1f3fa;
  padding: 15px;
  padding-bottom: 0; }

.grid__item.one-whole.threblk.thrd {
  background: #f8dede;
  padding: 15px;
  padding-bottom: 0; }

.grid__item.one-third.threblk {
  padding-left: 0;
  margin-left: 3%;
  width: 30%; }

.three_block_section {
  display: inline-block; }

.thre_text p {
  width: 71%;
  margin-bottom: 0;
  color: #000;
  min-height: 100px; }

.grid__item.one-whole.threblk.frst .thre_img img {
  bottom: 6.5em;
  position: relative;
  margin-bottom: -90%;
  right: -39px; }

.thre_img {
  float: right;
  margin-bottom: -23px; }

.grid__item.one-whole.threblk.scnd .thre_img img {
  bottom: 135px;
  position: relative;
  margin-bottom: -84%;
  width: 150px;
  right: -38px; }

.grid__item.one-whole.threblk.thrd .thre_img img {
  margin-bottom: -80%;
  position: relative;
  bottom: 8.5em;
  right: -65px; }

.thre_img_f {
  position: absolute;
  right: 20px;
  top: 35px; }

.thre_text a {
  font-weight: bold; }

.thre_text h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px; }

.underline {
  width: 100%;
  max-width: 80px;
  background: #ddd;
  height: 4px;
  border-radius: 5px;
  margin-bottom: 17.5px; }

.underline_in {
  width: 33.33%;
  float: left;
  background: #f5ce4e;
  height: 4px;
  border-radius: 5px; }

.h3, h4 {
  text-transform: uppercase; }

#shopify-section-1529628649715 {
  background: #f6f6f6; }

.underline.center {
  margin: auto; }

.textsection {
  width: 81%;
  margin: auto;
  padding-bottom: 30px;
  text-align: center; }

.textsection p {
  color: #000; }

.grid__item.medium-up--one-third.text-center.customsection {
  background: #fff;
  width: 30%;
  margin-left: 3%;
  padding-left: 0;
  padding: 30px;
  padding-top: 0; }

.grid__item.medium-up--one-third.text-center.customsection a {
  padding: 15px 30px;
  background: #3faa2a;
  color: #fff;
  border-radius: 3px; }

.grid__item.one-half.fourblk {
  width: 30%; }

.leftsection {
  display: inline-block;
  float: left;
  width: 30%; }

.rightsection {
  display: inline-block;
  float: right;
  width: 30%; }

.grid__item.one-half.fourblk {
  float: none; }

.grid__item.one-whole.fourblk {
  text-align: center; }

.grid__item.one-whole.fourblk.thirdblock {
  margin-top: 60px; }

.grid__item.one-whole.fourblk.fourthblock {
  margin-top: 60px; }

.section-header.text-center.hedr {
  margin-bottom: 30px; }

.centersection {
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 136px;
  bottom: 0px;
  left: 44%; }

.btnshopnow {
  text-align: center; }

.centersection a {
  padding: 15px 30px;
  background: #3faa2a;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 13px; }

.bannerbtn a {
  background: #3faa2a;
  color: #fff;
  padding: 15px 30px;
  border-radius: 3px;
  position: relative; }

#shopify-section-1529628617060 {
  background: #edf9f8; }

#shopify-section-1531491175365 {
  margin-top: -55px; }

.bannertext {
  padding-left: 25px;
  text-align: center;
  padding-bottom: 20px; }

.bannertext h2 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 0px; }

.iconbanner {
  float: left;
  padding-left: 30px; }

.iconbanner img {
  display: block;
  margin: auto; }

.banner_imgsection {
  position: relative;
  display: inline-block;
  padding-left: 70px;
  padding-bottom: 20px; }

.site-footer h2 {
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px; }

.headermiddle {
  width: 90%;
  float: left;
  margin-top: 10px; }

.headermiddle img {
  float: left;
  padding-right: 10px;
  padding-top: 3px; }

.numbertop {
  float: right;
  padding-left: 40px; }

.emailtop {
  float: right;
  padding-left: 40px; }

.collectiontopbar {
  text-align: center;
  background: url("https://cdn.shopify.com/s/files/1/0019/0355/8700/files/Untitled-1_956dd6c1-8321-4b73-aba1-f6f2acc70efe.png?17909686027945855024");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px; }

#shopify-section-collection-template {
  margin-top: -55px; }

.collectiontopbar h2 {
  font-size: 30px;
  font-weight: bold; }

.collectiontopbar p {
  width: 70%;
  margin: auto;
  color: #000; }

.btnaddtocart {
  text-align: center;
  background: #3faa2a;
  color: #fff;
  padding: 10px;
  border-radius: 3px;
  width: 50%;
  margin: auto;
  margin-top: auto;
  margin-top: 20px; }

.h4.grid-view-item__title {
  text-align: center; }

.grid-view-item__meta {
  text-align: center; }

.section-header.text-center h2 {
  font-size: 24px;
  font-weight: bold; }

.procuctrightitem h3 {
  font-size: 20px; }

.procuctrightitem .h4.grid-view-item__title {
  text-align: left; }

.section-header__title.center {
  text-align: center;
  font-weight: bold;
  font-size: 26px; }

#shopify-section-Product-des-section {
  padding-top: 20px;
  border-top: 1px solid #ACA079;
  border-bottom: 1px solid #ACA079;
  padding-bottom: 20px;
  margin-top: 50px; }

.product-single__meta h4 {
  margin-bottom: 0;
  color: #358fb1; }

.product-single__meta h3 {
  margin-bottom: 10px; }

.product-single__description.rte li {
  list-style: none;
  color: #000; }

.product-single__description.rte li {
  background: url("https://cdn.shopify.com/s/files/1/0019/0355/8700/files/Untitled-1_aebb9221-ac09-49c2-8e6c-250e774371d4.png?9239134254858118645");
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto auto;
  background-size: contain;
  height: 19px;
  background-repeat: no-repeat;
  position: relative;
  background-position: left;
  padding-left: 40px;
  line-height: 15px; }

.product-single__description.rte ul {
  margin-left: 0 !important; }

.product-single__description.rte p {
  color: #000; }

.grid__item.one-whole.fourblk.product_four {
  width: 25%; }

.grid__item.one-whole.fourblk.product_four img {
  padding-bottom: 20px; }

.product_info_left {
  width: 50%;
  float: left; }

.product_info_right {
  width: 50%;
  float: left;
  padding-left: 40px; }

.product_info_right_first {
  background: #aeaeae;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px; }

.product_info_right_first p {
  padding: 4px 15px; }

.product_info_right_second {
  background: #aeaeae;
  margin-top: 5px;
  padding: 15px; }

.product_info_right_third {
  padding-bottom: 30px; }

.product_info_right_fourth {
  background: #aeaeae; }

.product_info_right_fourth p {
  padding-left: 15px;
  font-size: 14px; }

.product_info_right p {
  color: #000; }

.product_info_left_bottom_left {
  width: 60%;
  margin-top: 20px;
  float: left; }

.product_info_left_bottom_left p {
  margin-bottom: 10px; }

.product_info_left_bottom_right {
  width: 40%;
  display: block;
  padding-left: 50px;
  overflow: hidden; }

.product_info_left_bottom_right img {
  display: block;
  margin: auto;
  padding-bottom: 5px; }

.product_info_left_bottom_right {
  text-align: center; }

.page-width.quotess {
  max-width: 950px; }

.quotes-wrapper .slick-dots li button::before {
  color: #f5ce4e !important;
  opacity: 0.2; }

#shopify-section-product_info_section {
  background: #edf9f8; }

.mainbnr {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 20px; }

.bannerbtn {
  display: block;
  margin: auto;
  max-width: 140px; }

.moblimg {
  display: none; }

.bannerbtn.mob {
  display: none; }

.thre_img.mob {
  display: none; }

.four_section_img {
  display: none; }

.headermiddle.mob {
  display: none; }

.site-header.border-bottom.logo--left {
  max-width: 1250px;
  margin: auto; }

.grid__item.product-single__photos.medium-up--one-half {
  border-right: 1px solid #888; }

.procuctleftitem {
  width: 50%;
  float: left; }

.procuctrightitem {
  width: 50%;
  float: left; }

.procuctrightitem .btnaddtocart {
  width: 100%; }

.procuctrightitem {
  position: relative;
  margin-top: 15%; }

#ProductSection-related-products .grid__item.small--one-half.medium-up--one-half {
  width: 45%;
  border: 1px solid #eee;
  margin-left: 5%;
  padding: 0 20px; }

#shopify-section-1529628649715 p {
  margin-bottom: 5px; }

#shopify-section-1529628649715 h3 {
  margin-bottom: 0; }

#shopify-section-collection-template h3 {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold; }

.grid-view-item__link.grid-view-item__image-container {
  border: 1px solid #eee;
  padding-bottom: 40px;
  margin-top: 40px; }

#shopify-section-collection-template .grid__item.grid__item--collection-template.small--one-whole.medium-up--one-third:nth-child(1) .h4.grid-view-item__title {
  color: #358fb1; }

#shopify-section-collection-template .grid__item.grid__item--collection-template.small--one-whole.medium-up--one-third:nth-child(2) .h4.grid-view-item__title {
  color: #913839; }

#shopify-section-collection-template .grid__item.grid__item--collection-template.small--one-whole.medium-up--one-third:nth-child(3) .h4.grid-view-item__title {
  color: #ba9e43; }

.grid.grid--uniform.grid--flush-bottom .grid__item.medium-up--one-third.text-center.customsection:nth-child(1) .h4 {
  color: #358fb1; }

.grid.grid--uniform.grid--flush-bottom .grid__item.medium-up--one-third.text-center.customsection:nth-child(2) .h4 {
  color: #913839; }

.grid.grid--uniform.grid--flush-bottom .grid__item.medium-up--one-third.text-center.customsection:nth-child(3) .h4 {
  color: #ba9e43; }

.grid.grid--uniform.grid--view-items .grid__item.small--one-half.medium-up--one-half:nth-child(1) .procuctrightitem .h4.grid-view-item__title {
  color: #913839; }

.grid.grid--uniform.grid--view-items .grid__item.small--one-half.medium-up--one-half:nth-child(2) .procuctrightitem .h4.grid-view-item__title {
  color: #ba9e43; }

#shopify-section-product_image_text {
  background: #ffd80033; }

.search-header.search {
  display: none; }

.carticon a {
  top: 20px; }

.quoteimg img {
  display: block;
  margin: auto;
  padding-bottom: 20px; }

.product-form__item--no-variants {
  max-width: 150px; }

.iconproduct img {
  width: 80px;
  padding-left: 15px; }

.iconproduct {
  padding-left: 20px; }

.product-single__price.product-single__price-product-template {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 0; }

.product-single__description.rte {
  margin-bottom: 5px; }

.social-sharing {
  padding-top: 20px; }

@media all and (min-width: 1440px) {
  #shopify-section-1531491175365 {
    height: 628px !important; }

  .mainbnr {
    padding-top: 100px; }

  .banner_imgsection {
    padding-bootom: 40px; } }
@media all and (max-width: 768px) {
  .iconproduct {
    margin: auto;
    padding-top: 20px; }

  #ProductSection-related-products .grid__item.small--one-half.medium-up--one-half {
    width: 100%;
    margin-top: 30px; }

  .btn.cstm {
    margin: auto;
    display: block;
    width: 120px;
    font-size: 13px; }

  .four_block_section {
    padding-bottom: 60px !important; }

  .headermiddle.mob {
    display: block; }

  .headermiddle.desktop {
    display: none; }

  .site-footer li {
    line-height: 16px !important; }

  .four_section_img img {
    width: 80%;
    display: block;
    margin: auto;
    padding-bottom: 30px; }

  .four_block_section {
    padding-bottom: 60px; }

  .centersection {
    left: 32%; }

  .site-header {
    border-bottom: 1px solid #f5ce4e; }

  .icon.icon-hamburger {
    color: #f5ce4e; }

  .js.lazyautosizes.lazyloaded {
    width: 130px; }

  .btn--link.site-header__search-toggle.js-drawer-open-top.medium-up--hide {
    display: none; }

  .site-header__cart {
    display: none; }

  .headermiddle {
    width: 70%;
    float: left;
    margin-top: 25px;
    padding-right: 35px; }

  .emailtop {
    padding-left: 24px;
    font-size: 12px; }

  .headermiddle img {
    float: left;
    padding-right: 3px;
    padding-top: 0px; }

  .numbertop {
    float: right;
    padding-left: 0;
    font-size: 12px; }

  .grid__item.one-whole.threblk.frst .thre_img img {
    bottom: 0;
    position: relative;
    margin-bottom: 0;
    right: 0; }

  .thre_img.mob {
    position: relative;
    float: none;
    margin-bottom: 0; }

  .thre_img_f.mob {
    position: relative;
    top: 13px; }

  .grid__item.one-whole.threblk.scnd .thre_img img {
    bottom: 0;
    position: relative;
    margin-bottom: 0;
    width: 150px;
    right: 0; }

  .grid__item.one-whole.threblk.thrd .thre_img img {
    margin-bottom: 0;
    position: relative;
    bottom: 0;
    right: 0; }

  .thre_text p {
    min-height: auto; }

  .grid__item.one-third.threblk {
    margin-left: 0; }

  .thre_img.mob {
    display: block; }

  .grid__item.one-whole.threblk.frst .thre_img_f.mob {
    position: relative;
    top: 0;
    width: 50%;
    float: left; }

  .grid__item.one-whole.threblk.frst .thre_img.mob {
    display: block;
    width: 50%;
    float: left; }

  .grid__item.one-whole.threblk.frst .thre_img img {
    float: left; }

  .grid__item.one-whole.threblk.frst .thre_img_f.mob img {
    float: right;
    padding-top: 20px; }

  #shopify-section-1531475010720 {
    padding-bottom: 0; }

  #shopify-section-1531456379266 {
    padding-top: 0; }

  .four_block_section {
    background: none !important; }

  .four_section_img {
    display: block; }

  .thre_img_f.desktp {
    display: none; }

  .bannerbtn.desktop {
    display: none; }

  .thre_img.desktp {
    display: none; }

  .bannerbtn.mob {
    display: block;
    margin-top: 20px; }

  .bannertext {
    padding-left: 0;
    padding-bottom: 5px; }

  .banner_imgsection {
    padding-left: 0;
    margin: auto;
    max-width: 290px;
    display: block;
    overflow: hidden; }

  #shopify-section-1531491175365 {
    background: none !important;
    height: auto !important; }

  .iconbanner {
    float: left;
    padding-left: 15px;
    width: 90px; }

  .iconbanner p {
    font-size: 10px;
    text-align: center; }

  .thre_text p {
    width: 100%; }

  .mainbnr {
    padding-top: 25px;
    padding-bottom: 0; }

  .moblimg {
    display: block; }

  .bannertext h2 {
    font-size: 28px; }

  .bannertext p {
    font-size: 12px; }

  .four_block_section {
    background: none; }

  .rightsection {
    padding-top: 30px; }

  .image_top {
    float: none; }

  .text_top {
    padding-left: 0; }

  .grid__item.one-whole.imgtop {
    padding-left: 0; }

  .grid__item.one-whole.fourblk.product_four {
    padding-bottom: 20px; }

  .product_info_left {
    width: 100%; }

  .product_info_left_bottom_left {
    width: 90%; }

  .product_info_left_bottom_right {
    width: 100%;
    padding-left: 0;
    padding-bottom: 30px; }

  .product_info_right {
    width: 100%;
    padding-left: 0; }

  .grid__item.one-whole.fourblk.product_four {
    width: 100%; }

  .grid__item.one-quarter.ftrtop {
    width: 50%;
    margin-bottom: 10px;
    margin-top: 10px; }

  .grid__item.one-fifth.footerlogoimg {
    width: 100%; }

  .grid__item.one-fifth.firstone {
    width: 30%;
    text-align: left; }

  .grid__item.one-fifth.secondone {
    width: 50%;
    text-align: left; }

  .grid__item.one-fifth.thirdone {
    width: 20%;
    text-align: left; }

  .grid__item.one-fifth.fourthone {
    width: 100%; }

  .grid__item.one-third.threblk {
    width: 100%;
    margin-bottom: 30px;
    text-align: center; }

  .grid__item.medium-up--one-third.text-center.customsection {
    width: 100%; }

  .leftsection {
    width: 100%; }

  .rightsection {
    width: 100%; }

  .site-footer li {
    padding-left: 0;
    font-size: 15px;
    line-height: 0; }

  .text_with_image_main {
    width: 100%;
    overflow: hidden;
    padding-top: 30px; }

  .image_top img {
    float: none;
    margin: auto;
    display: block;
    margin-bottom: 15px; }

  .text_top {
    text-align: center; }

  #shopify-section-1531423301741 .image-bar--small .image-bar__content, .image-bar--small .image-bar__item {
    height: 140px; } }
@media all and (max-width: 320px) {
  .headermiddle {
    margin-top: 20px;
    padding-right: 0; }

  .js.lazyautosizes.lazyloaded {
    width: 110px; }

  #shopify-section-1531423301741 .image-bar--small .image-bar__content, .image-bar--small .image-bar__item {
    height: 120px; } }
